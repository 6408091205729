import React from 'react'
import { useState } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { styled } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import 'date-fns'

const StyledTextField = styled(TextField)({
  width: '100%'
})

const InputOutlined = styled(StyledTextField)({
  borderColor: props => props.defaultBorderColor,
  '& .MuiInputBase-focused': {
    borderColor: props => props.focusedBorderColor,
  },
  '& label.Mui-focused': {
    color: props => props.focusedBorderColor,
  },
  '& .MuiFilledInput-root': {
    background: 'white',
    '&.Mui-focused fieldset': {
      borderColor: props => props.focusedBorderColor,

    }
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-input': {
    position: 'relative',
    left: 20
  },
  '& .MuiInputLabel-formControl': {
    color: '#000'
  },
  '& input': {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: props => props.placeholderColor,
      marginLeft: '15px',
      opacity: 1
    }
  }
})

InputOutlined.defaultProps = {
  variant: "outlined",
  placeholderColor: 'grey',
  focusedBorderColor: "#2785F2",
  defaultBorderColor: '#7C7980',
  background: 'white'
}

const InputAutocomplete = ({ placeholder }) => {
  const [value, setValue] = useState('')
  return (
    <Autocomplete
      id="combo-box-demo"
      options={[]}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => {
        params.inputProps.value = value
        return (
          <InputOutlined
              {...params}
              value='test'
              label={placeholder}
              variant="filled"
              onChange={(e) => setValue(e.target.value)}
          />
        )
      }}
    />
  )
}

InputAutocomplete.defaultProps = {
  placeholder: '',
  icon: <AccountCircle />
}

const InputDate = ({ label, handleDate }) => {
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date)
    handleDate(date)
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={label}
          style={{ margin: 0, width: '100%' }}
          value={selectedDate}
          onChange={handleDateChange}
          InputAdornmentProps={{ position: "start" }}
          KeyboardButtonProps={{'aria-label': 'change date'}}
          TextFieldComponent={TextField}
      />
    </MuiPickersUtilsProvider>
  )
}

InputDate.defaultProps = {
  label: 'Date',
  handleDate: () => { }
}


export { InputOutlined, InputAutocomplete, InputDate }
