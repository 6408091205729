import React from 'react'
import Grid from '@material-ui/core/Grid'
import Countdown from 'react-countdown'
import { Container } from '@material-ui/core'
import { Text } from 'components/FBUI/Text'
import { Flex, FlexColumn, FlexJustifyCenter } from 'components/FBUI/Flex'
import { isMobile } from 'react-device-detect'
import { Image } from 'components/FBUI/Image'
import heroThumbnail from 'assets/1123test.png'

// Renderer callback with condition
const renderer = ({ days, hours, minutes, completed }) => {
  if (completed) {
    // Render a complete state
    return '';
  } else {
    // Render a countdown
    return (
      <Flex style={{ marginTop: 50, marginBottom: 20 }}>
        <FlexColumn>
            <Text
                font="Quicksand"
                size={isMobile ? '20px' : '48px'}
                color='#3A3640'
                weight='600'
                height='48px'
                style={{
                    padding: '10px 20px',
                    background: '#fff',
                    borderRadius: 4,
                    marginRight: 10
                }}
            >
                {days}
            </Text>
            <Text
                font="Quicksand"
                size={'16px'}
                color='#3A3640'
                weight='400'
                style={{
                    position: 'relative',
                    top: 5,
                    textAlign: 'center'
                }}
            >
                Days
            </Text>
        </FlexColumn>
        <Text
            font="Quicksand"
            size={isMobile ? '20px' : '48px'}
            color='#3A3640'
            weight='600'
            height='48px'
        >
        :
        </Text>
        <FlexColumn>
            <Text
                font="Quicksand"
                size={isMobile ? '20px' : '48px'}
                color='#3A3640'
                weight='600'
                height='48px'
                style={{
                    padding: '10px 20px',
                    background: '#fff',
                    borderRadius: 4,
                    marginRight: 10,
                    marginLeft: 10
                }}
            >
                {hours}
            </Text>
            <Text
                font="Quicksand"
                size={'16px'}
                color='#3A3640'
                weight='400'
                style={{
                    position: 'relative',
                    top: 5,
                    textAlign: 'center'
                }}
            >
                Hours
            </Text>
        </FlexColumn>
        <Text
            font="Quicksand"
            size={isMobile ? '20px' : '48px'}
            color='#3A3640'
            weight='600'
            height='48px'
        >
        :
        </Text>
        <FlexColumn>
            <Text
                font="Quicksand"
                size={isMobile ? '20px' : '48px'}
                color='#3A3640'
                weight='600'
                height='48px'
                style={{
                    padding: '10px 20px',
                    background: '#fff',
                    borderRadius: 4,
                    marginLeft: 10
                }}
            >
                {minutes}
            </Text>
            <Text
                font="Quicksand"
                size={'16px'}
                color='#3A3640'
                weight='400'
                style={{
                    position: 'relative',
                    top: 5,
                    textAlign: 'center'
                }}
            >
                Minutes
            </Text>
        </FlexColumn>
      </Flex>
    );
  }
};

const Title = ({children}) => <Text
    font="Quicksand"
    size={isMobile ? '20px' : '48px'}
    color='#3A3640'
    weight='600'
    height='48px'
    style={{
        width: '100%',
        textAlign: 'center'
    }}
>
    {children}
</Text>

const SubTitle = ({children}) => <Text
    font="'Raleway', sans-serif"
    size={isMobile ? '14px' : '16px'}
    color='#48454D'
    weight='normal'
    height='22px'
    spacing='0.01em'
    style={{
        width: '100%',
        marginTop: 20,
        textAlign: 'center'
    }}
>
    {children}
</Text>

const LandingThumbnail = () => {
    var date = Date.now();
    var enddate = 1629707329373 + (86400000 * 18);
    var timeToRelease = enddate - date;
    return (
        <div style={{ backgroundImage: `linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 56.77%), url(${heroThumbnail})`, height: isMobile ? 725 : 527, width: '100%', backgroundSize: isMobile ? 'cover' : 'contain' }}>
            <Container style={{ position: 'relative', top: isMobile ? 100 : 50 }}>
                <FlexJustifyCenter>
                    <Image fileName='logoDark.svg' />
                </FlexJustifyCenter>
                <FlexJustifyCenter>
                    <Countdown date={Date.now() + timeToRelease} renderer={renderer} />
                </FlexJustifyCenter>
                <Title>Find a <span style={{ color: '#2785F2'}}>Better You</span> Abroad!</Title>
                <FlexJustifyCenter>
                    <Grid item xs={12} md={9}>
                        <SubTitle>
                            Fabya.com is an online open market space for overseas hospitals, clinics, and doctors to list and advertise their services. When traveling to another destination, the expectation is to have a unique experience, and the journey will change you. Fabya's goal is to provide a positive change mentally and physically when you book a treatment overseas. While you have your treatment, you will also have the opportunity to discover new destinations. On the Fabya platform, you can find the best hospitals & doctors at the most affordable rates. You are getting a fantastic vacation and the treatment you have been planning for in one package at a lower cost. Fabya is the new concept for healing mentally and physically.
                        </SubTitle>
                    </Grid>
                </FlexJustifyCenter>
            </Container>
        </div>
    )
}

export default LandingThumbnail