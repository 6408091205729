import { actions } from 'const'

const initState = {
	loading: false,
	data: null
}

const procedures = (state = initState, action) => { 
	switch (action.type) {
		case actions.PROCEDURES_REQUEST:
			return {
				loading: true,
				data: null
			}
		case actions.PROCEDURES_SUCCESS:
			return {
				loading: false,
				data: action.payload
			}
		case actions.PROCEDURES_FAILURE:
			return {
				loading: false,
				data: null
			}
		default:
			return state
	}
}

export default procedures