import { actions } from 'const'

const initState = {
    loggedIn: false,
    loading: false,
    user: null
}

const auth = (state = initState, action) => { 
    switch (action.type) {
        case actions.LOGIN_REQUEST:
            return {
                loading:true
            };
        case actions.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case actions.LOGIN_FAILURE:
            return {};
        case actions.LOGOUT:
            return {};
        default:
            return state
    }
};

export default auth;