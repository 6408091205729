import React from 'react'
import styled from 'styled-components'
import { withTheme } from '@material-ui/core/styles'

const Wrapper = withTheme(styled.div`
    min-height: 100vh;
    background: ${props => props.bgColor ? props.bgColor : props.theme.custom.background};
`)

export const Layout = ({ children, ...props }) => (
    <Wrapper {...props}>
        {children}
    </Wrapper>
)
