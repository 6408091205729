const initState = {
  loading: false,
  data: null
}

const addons = (state = initState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default addons

