import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Text } from 'components/FBUI/Text'
import { Container } from '@material-ui/core'
import { Layout } from 'components/FBUI/Layout'
import { FooterSmall } from 'components/Footer/FooterSmall'
import { InputOutlined } from 'components/FBUI/Input'
import { ButtonPrimary } from 'components/FBUI/Button'
import { OutlinedSelect } from 'components/FBUI/Select'
import { FlexJustifyCenter, FlexJustifyStart } from 'components/FBUI/Flex'
import { CheckboxOutlined } from 'components/FBUI/Checkbox'
import { Image } from 'components/FBUI/Image'

import LandingThumbnail from 'components/Thumbnails/LandingThumbnail'
import axios from 'axios'

const CheckboxLabel = ({ children, styles = {} }) => <Text
    font="'Raleway', sans-serif"
    size='13px'
    weight='600'
    color='#3A3640'
    style={styles}
>
    {children}
</Text>

const optionsList = [
	{
		label: 'Hospital/Clinic',
		value: 'hospital'
	},
    {
		label: 'Patient',
		value: 'patient'
	},
]

const Home = () => {
    const [isSubmited, setSubmited] = useState(true)
    const [form, setForm] = useState({
        fullName: '',
        email: '',
        inquiry: '',
        iAm: ''
    })
    const [firstCheckbox, setFirstCheckobx] = useState(false);
    const [secondCheckbox, setSecondCheckobx] = useState(false);

    const setFormField = (field, value) => {
        const newForm = Object.assign({}, form)
        newForm[field] = value
        setForm(newForm)
    }

    const handleInputChange = (e, field) => setFormField(field, e.target.value)
    const handleSelectChange = (value, field) => setFormField(field, value)

    const handleCheckboxChange = (event, field) => {
        if (field === 'firstCheckbox') {
            setFirstCheckobx(event.target.checked);
        } else {
            setSecondCheckobx(event.target.checked)
        }
    };

    const sendForm =  () => {
        axios.post('https://fabya.herokuapp.com/hospitals/inquiryEmail', form)
        setSubmited(false)
    }

    const isValid = firstCheckbox && secondCheckbox && form.fullName.length !== 0 && form.email.length !== 0 && form.inquiry.length !== 0 && form.iAm.length !== 0

    return (
        <>
            <Layout bgColor='#fff'>
                <LandingThumbnail />
                <Container style={{ marginTop: 80, marginBottom: 80 }}>
                    <Grid container justify="center">
                        {isSubmited ? (
                            <Grid item xs={12} md={4}>
                                <OutlinedSelect onUpdate={(value) => handleSelectChange(value, 'iAm')} title='I’m a' options={optionsList} width='100%' styles={{ marginBottom: 10 }} />
                                <InputOutlined onChange={(e) => handleInputChange(e, 'fullName')} label='Full Name' placeholder='Full Name' style={{ marginBottom: 15 }} />
                                <InputOutlined onChange={(e) => handleInputChange(e, 'email')} label='E-Mail' placeholder='E-Mail' style={{ marginBottom: 15 }} />
                                <InputOutlined onChange={(e) => handleInputChange(e, 'inquiry')} label='Inquiry' placeholder='Inquiry' style={{ marginBottom: 15 }} multiline rows={10} />
                                <br />
                                <FlexJustifyStart>
                                    <CheckboxOutlined checked={firstCheckbox} onChange={(e) => handleCheckboxChange(e, 'firstCheckbox')} />
                                    <CheckboxLabel styles={{ position: 'relative', top: 10 }}>
                                        Quisque gravida iaculis urna, at tincidunt orci pulvinar nec. Cras a quam enim.
                                    </CheckboxLabel>
                                </FlexJustifyStart>
                                <br />
                                <FlexJustifyStart>
                                    <CheckboxOutlined checked={secondCheckbox} onChange={(e) => handleCheckboxChange(e, 'secondCheckbox')} />
                                    <CheckboxLabel>
                                        Quisque gravida iaculis urna.
                                    </CheckboxLabel>
                                </FlexJustifyStart>
                                <br />
                                <ButtonPrimary
                                    onClick={() => isValid ? sendForm() : null}
                                    style={{ width: '100%', color: '#fff' }}
                                    color="primary"
                                >
                                    SAVE
                                </ButtonPrimary>
                            </Grid>
                        ) : (
                            <Grid item xs={12} md={6}>
                                <div style={{ height: '100vh' }}>
                                    <FlexJustifyCenter>
                                        <Image fileName='doctorIcon.svg' />
                                    </FlexJustifyCenter>
                                    <br />
                                    <Text
                                        font="'Raleway', sans-serif"
                                        size='24px'
                                        weight='600'
                                        color='#3A3640'
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    >
                                        Your inquiry has been sent!
                                    </Text>
                                    <br />
                                    <Text
                                        font="'Raleway', sans-serif"
                                        size='16px'
                                        weight='400'
                                        color='#3A3640'
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    >
                                        Nunc fringilla, libero a sagittis molestie, massa quam vestibulum lectus, sed ornare ante risus a ex. Pellentesque cursus sapien nisi, quis tempus augue aliquam sed.
                                    </Text>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </Layout>
            <FooterSmall />
        </>
    )
}

export default Home