import { actions } from 'const'

const initState = {
	loading: false,
	data: null
}

const qa = (state = initState, action) => { 
	switch (action.type) {
		case actions.QA_REQUEST:
			return {
				loading: true,
				data: null
			};
		case actions.QA_SUCCESS:
			return {
				loading: false,
				data: action.payload
			};
		case actions.QA_FAILURE:
			return {
				loading: false,
				data: null
			};
		default:
			return state
	}
}

export default qa