import React from 'react'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { withTheme } from '@material-ui/core/styles'

const Wrapper = withTheme(styled.div`
   background: ${props => props.theme.custom.dark};
`)

const CopyrightText = withTheme(styled(Typography)({
   color: props => props.theme.custom.lightGrey,
   fontFamily: "'Raleway', sans-serif !important",
   fontWeight: '700  !important',
   fontSize: '12px !important',
   lineHeight: '2.3rem !important'
}))

export const FooterSmall = () => (
    <Wrapper>
        <Container>
            <CopyrightText align="center">
                Copyright 2021 Fabya All Rights Reserved
            </CopyrightText>
        </Container>
    </Wrapper>
)