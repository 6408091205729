import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
import {
    qa,
    auth,
    media,
    doctor,
    doctors,
    travels,
    reviews,
    hospital,
    hospitals,
    procedures,
    specialities,
    treatments,
} from "store/reducers"
import addons from "store/reducers/addons"

const reducer = combineReducers({
    qa,
    auth,
    media,
    doctor,
    doctors,
    travels,
    reviews,
    hospital,
    hospitals,
    procedures,
    specialities,
    treatments,
    addons
})

const store = configureStore({
    reducer,
})

export default store