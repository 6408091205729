import React from 'react'
import styled from 'styled-components'
import { withTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

export const Text = withTheme(styled(Typography)({
	color: props => props.color + ' !important',
	fontSize: props => props.size + ' !important',
	fontFamily: props => props.font + ' !important',
	fontWeight: props => props.weight + ' !important',
	lineHeight: props => props.height + ' !important',
	letterSpacing: props => props.spacing + ' !important',
	textTransform: props => props.transform + ' !important',
	marginTop: props => props.top + '!important',
	letterspacing: props => props.spacing = '!important',
	...props => props
}))

Text.defaultProps = {
	color: '#7C7980',
	size: '10px',
	height: '22px',
	weight: 'initial',
	spacing: '1px',
	transform: 'initial',
	font: "'Raleway', sans-serif"
}

export const TextRaleway = (props) => <Text font="'Raleway', sans-serif" {...props} />

export const TextQuicksand = (props) => <Text font="Quicksand" {...props} />