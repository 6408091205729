import Button from '@material-ui/core/Button'
import { styled } from '@material-ui/core/styles'

const StyledButton = styled(Button)({
  border: 0,
  height: 48,
  padding: '0 30px',
})

const ButtonPrimary = styled(StyledButton)({
  boxShadow: 'none',
  borderRadius: 3,
  fontWeight: 700,
  overflow: 'hidden',
  color: props => props.color,
  background: props => props.background
})

ButtonPrimary.defaultProps = {
  color: '#fff',
  variant: 'outlined',
  background: 'linear-gradient(90deg, #5E51F1 -3.9%, #288DF7 100%)'
}

const ButtonOutlined = styled(StyledButton)({
  borderRadius: 4,
  fontWeight: 700,
  border: '1px solid',
  color:  props => props.color,
  background: props => props.background,
  borderColor: props => props.borderColor,
  minWidth: props => props.minWidth,
  overflow: 'hidden',
  ...props => props
})

ButtonOutlined.defaultProps = {
  color: '#3A3640',
  borderColor: '#7C7980',
  background: 'transparent',
  variant: 'outlined',
}

const ButtonTransparent = styled(StyledButton)({
  color:  props => props.color,
  overflow: 'hidden',
})

ButtonTransparent.defaultProps = {
  color: '#3A3640'
}

export {
  ButtonPrimary,
  ButtonOutlined,
  ButtonTransparent
}