import { createMuiTheme } from '@material-ui/core/styles'

export const lightTheme = {
  name: 'Light Theme',
  custom: {
    dark: '#3A3640',
    grey: '#7C7980',
    light: '#F5F1ED',
    darkGrey: '#48454D',
    brandColor: '#2785F2',
    solitude: '#EDF0F5',
    edgeWater: '#CAC5D2',
    lightGrey: '#CAC8CC',
    background: '#EDF0F5',
    brandGradient: 'linear-gradient(90deg, #5E51F1 -3.9%, #288DF7 100%)'
  },
  typography: {
    fontFamily: "'Quicksand', sans-serif",
    textTransform: "none",
  }
}

export default createMuiTheme(lightTheme)