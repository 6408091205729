import React from 'react'
import {
  Route,
  Switch,
  BrowserRouter as Router,
} from 'react-router-dom'
import { routes } from 'const'
import { Redirect } from 'react-router'

import Home from 'pages/Home'

const Routes = () => (
  <Router>
    <Switch>
      <Route path={routes.home} component={Home} exact />
      <Redirect from='*' to='/' />
    </Switch>
  </Router>
)

export default Routes
