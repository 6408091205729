import { actions } from 'const'

const initState = {
	loading: false,
	data: null
}

const travels = (state = initState, action) => { 
	switch (action.type) {
		case actions.TRAVELS_REQUEST:
			return {
				loading: true,
				data: null
			}
		case actions.TRAVELS_SUCCESS:
			return {
				loading: false,
				data: action.payload
			}
		case actions.TRAVELS_FAILURE:
			return {
				loading: false,
				data: null
			}
		default:
			return state
	}
}

export default travels