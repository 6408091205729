import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: '100%'
  },
  formControl: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    // "& $notchedOutline": {
    //   borderColor: theme.custom.edgeWater
    // },
    // "&:hover $notchedOutline": {
    //   borderColor: theme.custom.edgeWater
    // },
    // "&$focused $notchedOutline": {
    //   borderColor: theme.custom.brandColor
    // }
  },
  focused: {},
  notchedOutline: {}
}))

const useTransparentInputStyles = makeStyles(theme => ({
  root: {
    "& $notchedOutline": {
      borderColor: "white"
    },
    "&:hover $notchedOutline": {
      borderColor: "white"
    },
    "&$focused $notchedOutline": {
      borderColor: "white"
    }
  },
  focused: {},
  notchedOutline: {}
}))

export const OutlinedSelect = ({ title, options, width, styles, onUpdate }) => {
  const classes = useStyles()
  const outlinedInputClasses = useOutlinedInputStyles()
  const [values, setValues] = React.useState({age: "" })
  const [labelWidth, setLabelWidth] = React.useState(0)
  const inputLabel = React.useRef(null)
  
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  function handleChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }))

    onUpdate(event.target.value)
  }

  return (
    <div style={{ width, ...styles }}>
      <form className={classes.root} autoComplete="off">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            style={{ fontWeight: 400, fontSize: 14, fontFamily: 'Raleway, sans-serif', color: 'black' }}
            ref={inputLabel}
            htmlFor="outlined-age-simple"
          >
            {title}
          </InputLabel>
          <Select
            value={values.age}
            onChange={handleChange}
            input={
              <OutlinedInput
                labelWidth={labelWidth}
                name="age"
                id="outlined-age-simple"
                classes={outlinedInputClasses}
              />
            }
          >
            {options.map(({ value, label }) => <MenuItem value={value}>{label}</MenuItem>)}
          </Select>
        </FormControl>
      </form>
    </div>
  )
}

export const TransparentSelect = ({ title, options, width, styles }) => {
  const classes = useStyles()
  const outlinedInputClasses = useTransparentInputStyles()
  const [values, setValues] = React.useState({age: "" })
  const [labelWidth, setLabelWidth] = React.useState(0)
  const inputLabel = React.useRef(null)
  
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  function handleChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }))
  }

  return (
    <div style={{ width, ...styles }}>
      <form className={classes.root} autoComplete="off">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            style={{ fontWeight: 600, fontSize: 14, fontFamily: 'Raleway, sans-serif', color: '#6F6C83' }}
            ref={inputLabel}
            htmlFor="outlined-age-simple"
          >
            {title}
          </InputLabel>
          <Select
            value={values.age}
            onChange={handleChange}
            input={
              <OutlinedInput
                labelWidth={labelWidth}
                name="age"
                id="outlined-age-simple"
                style={{ border: 0 }}
                classes={outlinedInputClasses}
              />
            }
          >
            {options.map(({ value, label }) => <MenuItem value={value}>{label}</MenuItem>)}
          </Select>
        </FormControl>
      </form>
    </div>
  )
}

OutlinedSelect.defaultProps = {
    options: [],
    title: '',
    width: 120,
    styles: {}
}