import styled from 'styled-components'

export const Flex = styled.div`
	display: flex;
	flex-wrap: ${props => props.wrap};
	align-items: ${props => props.align};
	justify-content: ${props => props.justify};
	flex-direction: ${props => props.direction};
`

Flex.defaultProps = {
	wrap: 'no-wrap',
	align: 'center',
	justify: 'center',
	direction: 'row',
}

export const FlexBetween = ({ children, ...props }) => (
	<Flex justify='space-between' {...props}>
		{children}
	</Flex>
)

export const FlexJustifyCenter = ({ children, ...props }) => (
	<Flex justify='center' {...props}>
		{children}
	</Flex>
)

export const FlexJustifyStart  = ({ children, ...props }) => (
	<Flex justify='flex-start' {...props}>
		{children}
	</Flex>
)

export const FlexJustifyEnd  = ({ children, ...props }) => (
	<Flex justify='flex-end' {...props}>
		{children}
	</Flex>
)

export const FlexAlignCenter = ({ children, ...props }) => (
	<Flex align='center' {...props}>
		{children}
	</Flex>
)

export const FlexAround = ({ children, ...props }) => (
	<Flex justify='space-around'  {...props}>
		{children}
	</Flex>
)

export const FlexWrap = ({ children, ...props }) => (
	<Flex wrap='wrap'  {...props}>
		{children}
	</Flex>
)

export const FlexColumn = ({ children, ...props }) => (
	<Flex direction='column'  {...props}>
		{children}
	</Flex>
)

