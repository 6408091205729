const actions = {
    LOGOUT: 'LOGOUT',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    
    DOCTORS_REQUEST: 'DOCTORS_REQUEST',
    DOCTORS_SUCCESS: 'DOCTORS_SUCCESS',
    DOCTORS_FAILURE: 'DOCTORS_FAILURE',

    DOCTOR_REQUEST: 'DOCTOR_REQUEST',
    DOCTOR_SUCCESS: 'DOCTOR_SUCCESS',
    DOCTOR_FAILURE: 'DOCTOR_FAILURE',
    
    HOSPITALS_REQUEST: 'HOSPITALS_REQUEST',
    HOSPITALS_SUCCESS: 'HOSPITALS_SUCCESS',
    HOSPITALS_FAILURE: 'HOSPITALS_FAILURE',

    HOSPITAL_REQUEST: 'HOSPITAL_REQUEST',
    HOSPITAL_SUCCESS: 'HOSPITAL_SUCCESS',
    HOSPITAL_FAILURE: 'HOSPITAL_FAILURE',
    
    PROCEDURES_REQUEST: 'PROCEDURES_REQUEST',
    PROCEDURES_SUCCESS: 'PROCEDURES_SUCCESS',
    PROCEDURES_FAILURE: 'PROCEDURES_FAILURE',
    
    REVIEWS_REQUEST: 'REVIEWS_REQUEST',
    REVIEWS_SUCCESS: 'REVIEWS_SUCCESS',
    REVIEWS_FAILURE: 'REVIEWS_FAILURE',
    
    TRAVELS_REQUEST: 'TRAVELS_REQUEST',
    TRAVELS_SUCCESS: 'TRAVELS_SUCCESS',
    TRAVELS_FAILURE: 'TRAVELS_FAILURE',

    MEDIA_REQUEST: 'MEDIA_REQUEST',
    MEDIA_SUCCESS: 'MEDIA_SUCCESS',
    MEDIA_FAILURE: 'MEDIA_FAILURE',

    QA_REQUEST: 'QA_REQUEST',
    QA_SUCCESS: 'QA_SUCCESS',
    QA_FAILURE: 'QA_FAILURE ',

    SPECIALITIES_REQUEST: 'SPECIALITIES_REQUEST',
    SPECIALITIES_SUCCESS: 'SPECIALITIES_SUCCESS',
    SPECIALITIES_FAILURE: 'SPECIALITIES_FAILURE',

    TREATMENTS_REQUEST: 'TREATMENTS_REQUEST',
    TREATMENTS_SUCCESS: 'TREATMENTS_SUCCESS',
    TREATMENTS_FAILURE: 'TREATMENTS_FAILURE',
}

export default actions