import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import { styled, withTheme } from '@material-ui/core/styles'

const CheckboxOutlined = withTheme(styled(Checkbox)({
    color: props => props.theme.custom.brandColor
}))

CheckboxOutlined.defaultProps = {
    checkedIcon: <CheckBoxOutlinedIcon />,
    icon: <CheckBoxOutlineBlankIcon />,
    color: '#2785F2'
}

const CheckboxPrimary = withTheme(styled(Checkbox)({
    color: props => props.theme.custom.brandColor
}))

CheckboxPrimary.defaultProps = {
    checkedIcon: <CheckBoxIcon />,
    icon: <CheckBoxOutlineBlankIcon />,
    color: '#2785F2'
}

export { CheckboxOutlined, CheckboxPrimary }